import { Box } from '@branch-messenger/willow-ui'
import { FC } from 'react'

import { OptionItem, OptionItemType } from './OptionItem'

interface Props {
  options: OptionItemType[]
}

export const OptionsMenu: FC<Props> = ({ options }) => (
  <Box $direction="column" $display="flex" $mt={4}>
    {options.map(option => (
      <OptionItem key={option.title} {...option} />
    ))}
  </Box>
)
