import styled, { css } from 'styled-components'

export const AccountManagementDetailContainer = styled.button`
  display: flex;
  align-items: center;
  border: none;
  justify-content: space-between;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii.md};
  padding-left: 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.hoverGray};
  }
  &:disabled {
    &:hover {
      background-color: transparent;
    }
    ${({ theme }) => css`
      color: ${theme.colors.textDark};
      cursor: not-allowed;
    `}
  }
`

export const VerifiedBadge = styled.span<{ $verified: boolean }>`
  ${({ theme, $verified }) => css`
    background-color: ${$verified ? theme.colors.brand : theme.colors.gray};
    border-radius: ${theme.radii.md};
    font-weight: bold;
    ${theme.sizeVariant.md}
  `}
`
