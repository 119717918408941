import {
  LinkCreationProvider,
  steps,
  useLinkCreation,
} from './LinkCreationContext'

export const LinkCreationComponent = () => {
  const { currentStep } = useLinkCreation()
  const StepComponent = steps[currentStep]

  return <StepComponent />
}

export const LinkCreation = () => (
  <LinkCreationProvider>
    <LinkCreationComponent />
  </LinkCreationProvider>
)
