import { Box, Typography } from '@branch-messenger/willow-ui'
import { ButtonHTMLAttributes, FC } from 'react'

import { AccountManagementDetailContainer } from './styles'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string
  content?: string
  endAdornment?: JSX.Element
}

export const AccountManagementDetail: FC<Props> = ({
  title,
  content,
  endAdornment,
  ...rest
}) => {
  return (
    <AccountManagementDetailContainer {...rest}>
      <Box
        $display="flex"
        $direction="column"
        $align="start"
        $fullWidth
        $gap={2}
      >
        <Typography $bold>{title}</Typography>
        <Typography $size="sm">{content}</Typography>
      </Box>
      {endAdornment ? endAdornment : null}
    </AccountManagementDetailContainer>
  )
}
