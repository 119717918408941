import { Box, Button, Typography } from '@branch-messenger/willow-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { ChangeEvent, FC, useMemo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { StyledDigitInput } from './styles'

type Props = {
  handlePinSubmit: (pin: string[]) => void
  errorMessage?: string
  submitButtonLabel?: string
  incompleteButtonLabel?: string
  numberOfInputs?: number
  isPending?: boolean
}

type FormValues = {
  pin: string[]
}

const schema = yup.object().shape({
  pin: yup
    .array()
    .of(
      yup
        .string()
        .matches(/^\d$/, 'Each digit must be a number')
        .required('Each digit is required')
    )
    .length(4, 'PIN must be exactly 4 digits')
    .required('PIN is required'),
})

export const PinInput: FC<Props> = ({
  handlePinSubmit,
  isPending = false,
  errorMessage = '',
  submitButtonLabel = 'Submit',
  incompleteButtonLabel = 'Enter PIN',
  numberOfInputs = 4,
}) => {
  const { control, handleSubmit, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      pin: new Array(numberOfInputs).fill(''),
    },
    resolver: yupResolver(schema),
  })

  const pin = watch('pin')
  const inputRefs = useRef<HTMLInputElement[]>([])

  const handlePinChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target
    if (isNaN(Number(value))) return
    const updatedPin = [...pin]
    updatedPin[index] = value
    setValue('pin', updatedPin)

    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      const focusedIndex = inputRefs.current.findIndex(
        input => document.activeElement === input
      )

      if (focusedIndex >= 0) {
        const updatedPin = [...pin]
        updatedPin[focusedIndex] = ''
        setValue('pin', updatedPin)

        if (focusedIndex > 0 && pin[focusedIndex] === '') {
          inputRefs.current[focusedIndex - 1].focus()
        }
      }
    }
  }

  const validPin = useMemo(() => !pin.some(digit => digit === ''), [pin])

  const onSubmit = (data: FormValues) => {
    handlePinSubmit(data.pin)
  }

  return (
    <Box
      as="form"
      $display="flex"
      $direction="column"
      $gap={8}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box $display="flex" $gap={2}>
        {pin.map((_, index) => (
          <Controller
            key={index}
            name={`pin.${index}`}
            control={control}
            render={({ field }) => {
              return (
                <StyledDigitInput
                  $complete={validPin}
                  type="text"
                  maxLength={1}
                  pattern="\d*"
                  inputMode="numeric"
                  value={field.value}
                  onChange={event => {
                    field.onChange(event)
                    handlePinChange(index, event)
                  }}
                  onKeyDown={handleKeyDown}
                  ref={el =>
                    (inputRefs.current[index] = el as HTMLInputElement)
                  }
                />
              )
            }}
          />
        ))}
      </Box>
      {errorMessage && <Typography $color="alert">{errorMessage}</Typography>}
      <Box $display="flex" $justify="end" $fullWidth>
        <Button
          type="submit"
          disabled={!validPin || isPending}
          iconRight
          loading={isPending}
        >
          {validPin ? submitButtonLabel : incompleteButtonLabel}
        </Button>
      </Box>
    </Box>
  )
}
