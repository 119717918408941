import { Box, Icon, Label } from '@branch-messenger/willow-ui'
import { Lock01 } from '@branch-messenger/willow-ui/icons'
import { useSuspenseQuery } from '@tanstack/react-query'
import { FC } from 'react'

import { Title } from '@/components/typography'
import { formatPhoneNumber } from '@/lib/phone'
import { userQueryOptions } from '@/queries/auth'

import { AccountManagementDetail } from './AccountManagementDetail'
import { UpdatePrompt } from './UpdatePrompt'

export const AccountManagement: FC = () => {
  const { data: user } = useSuspenseQuery(userQueryOptions)

  return (
    <Box
      $display="flex"
      $direction="column"
      $align="start"
      $justify="start"
      $gap={4}
      style={{ height: '100%' }}
      $fullWidth
    >
      <Title>Manage your account.</Title>
      <Label $color="textMuted" $uppercase>
        Account Info
      </Label>
      <AccountManagementDetail
        title="Name"
        content={`${user?.first_name} ${user?.last_name}`}
        endAdornment={<Icon Icon={Lock01} size={6} />}
        disabled
      />
      <UpdatePrompt
        type="phone"
        text={formatPhoneNumber(user?.phone || '')}
        verified={user?.phone_verified}
      />
      <UpdatePrompt
        type="email"
        text={user?.email}
        verified={user?.email_verified}
      />
    </Box>
  )
}
