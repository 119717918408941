import { ColorKey } from '@branch-messenger/willow-ui'
import { useMemo } from 'react'

import { isValidPassword } from '@/lib/passwordStrength'

export const usePasswordStrengthValidator = (password: string) => {
  const validityCheck = useMemo(() => isValidPassword(password), [password])

  const pWStrengthLabel = useMemo(() => {
    const { pwStrength } = validityCheck
    if (pwStrength >= 50 && pwStrength < 60) return 'Fair'
    if (pwStrength >= 60 && pwStrength < 80) return 'Good'
    if (pwStrength >= 80) return 'Strong'
    return 'Weak'
  }, [validityCheck])

  const pWStrengthColor: ColorKey = useMemo(() => {
    const { pwStrength } = validityCheck
    if (pwStrength >= 50 && pwStrength < 60) return 'warning'
    if (pwStrength >= 60 && pwStrength < 80) return 'selection'
    if (pwStrength >= 80) return 'success'
    return 'alert'
  }, [validityCheck])

  return { ...validityCheck, pWStrengthLabel, pWStrengthColor }
}
