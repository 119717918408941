import {
  Box,
  Button,
  CardRadioItem,
  CardRadioRoot,
  Typography,
} from '@branch-messenger/willow-ui'
import { Branch, CreditCard01 } from '@branch-messenger/willow-ui/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from '@tanstack/react-router'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { usePayoutFee } from '@/hooks/usePayoutFee'
import { PaymentType } from '@/types/profile'

import { PaymentSelectionBadge } from './styles'

type FormValues = {
  paymentType: PaymentType
}

const schema = yup.object().shape({
  paymentType: yup
    .mixed<PaymentType>()
    .oneOf(Object.values(PaymentType), 'Please select a payment type')
    .required('Payment type is required'),
})

export const PaymentSelection = () => {
  const navigate = useNavigate()
  const { formattedFee } = usePayoutFee()
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  const badgeText = useMemo(() => {
    return formattedFee ? `${formattedFee}` : ''
  }, [formattedFee])

  const onSubmit = (data: FormValues) => {
    if (data.paymentType === PaymentType.CARD) {
      navigate({ to: '/setup/branch-informational' })
    } else if (data.paymentType === PaymentType.WALLET) {
      navigate({ to: '/setup/additional-info' })
    }
  }

  return (
    <Box
      as="form"
      $display="flex"
      $direction="column"
      $align="start"
      $gap={8}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box $display="flex" $direction="column" $align="start" $gap={4}>
        <Typography $size="xxl" $bold>
          Fast Pay, Your Way
        </Typography>
        <Typography $size="sm">
          Receive fast payouts to either a digital wallet for free with the
          Branch App or to an existing bank account for a small fee.
        </Typography>
      </Box>
      <Controller
        name="paymentType"
        control={control}
        render={({ field }) => (
          <CardRadioRoot {...field} onValueChange={field.onChange}>
            <CardRadioItem
              value={PaymentType.WALLET}
              Icon={Branch}
              title="Branch App"
              subtitle="Get paid quickly to the Branch App, which includes a fee-free digital bank account, debit card, financial wellness tools, and more."
            >
              <PaymentSelectionBadge $size="sm">Free</PaymentSelectionBadge>
            </CardRadioItem>
            <CardRadioItem
              value={PaymentType.CARD}
              Icon={CreditCard01}
              title={`Direct via a debit card`}
              subtitle="Send earnings directly to an existing personal bank account by linking your debit card."
            >
              <PaymentSelectionBadge $size="sm">
                {badgeText}
              </PaymentSelectionBadge>
            </CardRadioItem>
          </CardRadioRoot>
        )}
      />
      <Button type="submit" disabled={!isValid}>
        {isValid ? 'Continue' : 'Select Option'}
      </Button>
    </Box>
  )
}
