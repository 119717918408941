import { Box, Dialog, Icon, Typography } from '@branch-messenger/willow-ui'
import { ChevronRight, Pencil01 } from '@branch-messenger/willow-ui/icons'
import { useNavigate } from '@tanstack/react-router'
import { FC } from 'react'

import { NewOptionItem } from '@/components/options'

import { AccountManagementDetail } from './AccountManagementDetail'
import { VerifiedBadge } from './styles'

type Props = {
  type: 'phone' | 'email'
  text?: string
  verified: boolean
}

export const UpdatePrompt: FC<Props> = ({ type, text, verified }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    type === 'phone'
      ? navigate({ to: '/account/phone-update' })
      : navigate({ to: '/account/email-update' })
  }
  const title = type === 'phone' ? 'Phone Number' : 'Email Address'

  return (
    <Dialog
      trigger={
        <AccountManagementDetail
          title={title}
          content={text}
          endAdornment={
            <Box $display="flex" $gap={4}>
              <VerifiedBadge $verified={verified}>
                {verified ? `Verified` : `Unverified`}
              </VerifiedBadge>
              <Icon Icon={ChevronRight} size={6} />
            </Box>
          }
        />
      }
      title={title}
      description={
        <span>
          You’re updating details for: <strong>{text}.</strong>
        </span>
      }
    >
      <Box $mt={4}>
        <NewOptionItem icon={Pencil01} onClick={handleClick}>
          <Typography $bold>Update {title}</Typography>
        </NewOptionItem>
      </Box>
    </Dialog>
  )
}
