import { Badge } from '@branch-messenger/willow-ui'
import { FC } from 'react'

import { BranchDirectCardStatus, WalletStatus } from '@/types/profile'

type Props = {
  status: string | WalletStatus
}

export const AccountStatusBadge: FC<Props> = ({ status }) => {
  switch (status) {
    case BranchDirectCardStatus.COMPLETE:
      return <Badge $type="success">Connected</Badge>
    case BranchDirectCardStatus.INCOMPLETE:
      return <Badge $type="alert">Incomplete</Badge>
    default:
      return null
  }
}
