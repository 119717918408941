import { Box, Button, Icon, IconButton } from '@branch-messenger/willow-ui'
import { ArrowLeft, Bank } from '@branch-messenger/willow-ui/icons'
import { useNavigate } from '@tanstack/react-router'
import { FC } from 'react'

import { Description, Title } from '@/components/typography'

import { Information } from './Information'

const info = {
  icon: Bank,
  title: 'Banking Services',
  description: `Banking services that support Branch’s services are provided by Evolve Bank & Trust, Member FDIC (“Evolve”).`,
}

export const BranchInformational: FC = () => {
  const navigate = useNavigate()

  const nextStep = () => {
    navigate({ to: '/setup/terms-agreement' })
  }

  const backStep = () => {
    navigate({ to: '/setup/payment-selection' })
  }

  return (
    <Box $display="flex" $direction="column" $align="start">
      <IconButton onClick={backStep}>
        <Icon Icon={ArrowLeft} size={6} />
      </IconButton>
      <Title>Branch Direct makes pay access simple.</Title>
      <Description size="sm" css={{ mt: '$16' }}>
        Branch Direct is Branch’s quick, easy way for workers to receive payouts
        directly to their existing bank account via debit card. Branch is not a
        bank, but a technology company.
      </Description>
      <Box $my={10}>
        <Information key={info.title} {...info} />
      </Box>
      <Box $mt={4} $mb={10}>
        <Button onClick={nextStep}>Continue</Button>
      </Box>
    </Box>
  )
}
