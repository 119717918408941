import {
  Box,
  Button,
  Icon,
  Input,
  PasswordInput,
} from '@branch-messenger/willow-ui'
import { ChevronRight } from '@branch-messenger/willow-ui/icons'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Description, Title, Typography } from '@/components/typography'
import { useLogin } from '@/queries/auth'

export const LoginFormContent: FC = () => {
  const loginRequest = useLogin()
  const navigate = useNavigate()
  const { org_name } = useSearch({ from: '/login' })
  const {
    register,
    control,
    formState: { isValid },
  } = useFormContext()

  const resetPassword = () =>
    navigate({
      to: '/request-password-reset',
    })

  return (
    <>
      <Title>Welcome Back</Title>
      <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
        {`Login to your account and take control of how you're paid${org_name ? ` with ${org_name}` : ''}.`}
      </Description>
      <Box $display="flex" $direction="column" $gap={8}>
        <Box $display="flex" $direction="column" $gap={4} $fullWidth>
          <Input
            {...register('email')}
            type="email"
            placeholder="Enter Email"
            label="Email Address"
          />
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <PasswordInput
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                label="Password"
              />
            )}
          />
        </Box>

        <Box
          $display="flex"
          $direction="column"
          $align="start"
          $fullWidth
          $gap={2}
        >
          <Typography size="sm" color="muted">
            Forgot password?
          </Typography>
          <Button
            type="button"
            variant="link"
            onClick={resetPassword}
            iconRight={<Icon Icon={ChevronRight} />}
          >
            Reset
          </Button>
        </Box>
        <Box $display="flex" $fullWidth>
          <Button
            type="submit"
            disabled={!isValid || loginRequest.isPending}
            loading={loginRequest.isPending}
          >
            {' '}
            {isValid ? 'Login' : 'Enter Info'}
          </Button>
        </Box>
      </Box>
    </>
  )
}
