import { Box, Button, Icon, Typography } from '@branch-messenger/willow-ui'
import { ChevronRight } from '@untitled-ui/icons-react'
import { useContext } from 'react'

import { WillowTitle } from '@/components/typography'
import { DialogContext } from '@/context'
import { useSearchRosterMutationState } from '@/queries/registration'
import { InputType } from '@/types/registration'

import { GetHelpContent } from './GetHelpContent'
import { useLinkCreation } from './LinkCreationContext'

export const LinkCreated = () => {
  const { showDialog, hideDialog } = useContext(DialogContext)
  const { chosenVerificationMethod, setCurrentStep } = useLinkCreation()

  const searchRoster = useSearchRosterMutationState()

  const openGetHelpDialog = () => {
    showDialog({
      title: 'Get Help',
      description:
        'Check with your organization to ensure that the correct email and phone number are on file for you.',
      children: (
        <GetHelpContent
          hideDialog={hideDialog}
          resetForm={() => setCurrentStep('PhoneEmailForm')}
          inputType={chosenVerificationMethod}
        />
      ),
    })
  }

  return (
    <Box $display="flex" $direction="column" $align="start" $gap={4}>
      <WillowTitle>
        Check your{' '}
        {chosenVerificationMethod === InputType.EMAIL ? 'email' : 'phone'}.
      </WillowTitle>
      <Typography>
        You should receive{' '}
        {chosenVerificationMethod === InputType.EMAIL ? 'an email' : 'a phone'}{' '}
        message within a few minutes containing a secure link to connect a debit
        card and set up instant payouts to your existing bank account.
      </Typography>
      <Box $display="flex" $gap={2}>
        <Typography>
          Please use the secure link sent to:{' '}
          <strong>
            {chosenVerificationMethod === InputType.EMAIL
              ? searchRoster.email
              : searchRoster.phone}
          </strong>
        </Typography>
      </Box>
      <Box $display="flex" $direction="column" $gap={4} $align="start">
        <Typography $color="textMuted">Didn’t get a link?</Typography>
        <Button
          variant="link"
          onClick={openGetHelpDialog}
          iconRight={<Icon Icon={ChevronRight} />}
        >
          Get Help
        </Button>
      </Box>
    </Box>
  )
}
