import {
  AlertDialog,
  AlertDialogProps,
  Dialog,
  DialogProps,
} from '@branch-messenger/willow-ui'
import { createContext, FC, ReactNode, useState } from 'react'

import { DialogContextType } from '@/types/dialog'

export const DialogContext = createContext<DialogContextType>({
  showAlert: () => {},
  hideAlert: () => {},
  showDialog: () => {},
  hideDialog: () => {},
})

const initialAlertProps: AlertDialogProps = {
  title: '',
  description: '',
  actionDescription: '',
  cancelDescription: '',
  handleAction: () => {},
}

const initialDialogProps: DialogProps = {
  title: '',
  description: '',
  children: null,
}

type Props = {
  children: ReactNode
}

export const DialogProvider: FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [alertProps, setAlertProps] = useState(initialAlertProps)
  const [dialogProps, setDialogProps] = useState(initialDialogProps)

  const showAlert = (props: AlertDialogProps) => {
    hideDialog()
    setAlertProps(props)
    setOpen(true)
  }

  const hideAlert = () => {
    setAlertProps(initialAlertProps)
    setOpen(false)
  }

  const showDialog = (props: DialogProps) => {
    hideAlert()
    setDialogProps(props)
    setDialogOpen(true)
  }

  const hideDialog = () => {
    setDialogProps(initialDialogProps)
    setDialogOpen(false)
  }

  const dialogContextValues = {
    showAlert,
    hideAlert,
    showDialog,
    hideDialog,
  }

  return (
    <DialogContext.Provider value={dialogContextValues}>
      <AlertDialog
        {...alertProps}
        onOpenChange={hideAlert}
        open={open}
        size="sm"
      />
      <Dialog {...dialogProps} open={dialogOpen} onOpenChange={hideDialog}>
        {dialogProps.children}
      </Dialog>
      {children}
    </DialogContext.Provider>
  )
}
