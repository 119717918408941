import styled, { css } from 'styled-components'

export const StyledDigitInput = styled.input<{ $complete?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background};
  width: 50px;
  height: 50px;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme.colors.textPlaceholder};
  border-radius: ${({ theme }) => theme.radii.md};
  text-align: center;

  ${({ $complete, theme }) =>
    $complete &&
    css`
      box-shadow: ${theme.shadows.popOver};
      background-color: white;
    `}
`
