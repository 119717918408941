import { createContext, FC, ReactNode, useContext, useState } from 'react'

import { InputType } from '@/types/registration'

import { LinkCreated } from './LinkCreated'
import { PhoneEmailForm } from './PhoneEmailForm'
import { VerificationMethod } from './VerificationMethod'

export const steps = {
  PhoneEmailForm: PhoneEmailForm,
  VerificationMethod: VerificationMethod,
  LinkCreated: LinkCreated,
}

type Step = keyof typeof steps

type Props = {
  children: ReactNode
}

type LinkCreationContextProps = {
  currentStep: Step
  setCurrentStep: (step: Step) => void
  chosenVerificationMethod: InputType
  setChosenVerificationMethod: (method: InputType) => void
}

const LinkCreationContext = createContext<LinkCreationContextProps | undefined>(
  undefined
)

export const LinkCreationProvider: FC<Props> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<Step>('PhoneEmailForm')
  const [chosenVerificationMethod, setChosenVerificationMethod] =
    useState<InputType>(InputType.PHONE)

  return (
    <LinkCreationContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        chosenVerificationMethod,
        setChosenVerificationMethod,
      }}
    >
      {children}
    </LinkCreationContext.Provider>
  )
}

export const useLinkCreation = () => {
  const context = useContext(LinkCreationContext)
  if (!context) {
    throw new Error(
      'useLinkCreation must be used within a LinkCreationProvider'
    )
  }
  return context
}
