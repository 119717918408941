import {
  AuthResponse,
  LoginRequestBody,
  LoginWorkerData,
  LoginWorkerPayload,
  ResendOTP,
  User,
  VerifyLoginResponse,
} from '@/types/auth'

import { http } from './http'

/**
 * Verifies login credentials and initiate OTP.
 * @param data Contains user credentials
 */
export const login = (data: LoginRequestBody) =>
  http.post<AuthResponse>(`/login`, data)

/**
 * Resend OTP.
 * @param data Contains user credentials including verification channel (PHONE or EMAIL)
 */
export const resendOtp = (data: ResendOTP) => http.post(`/login/send-otp`, data)

/**
 * Verifies OTP.
 * @param data Contains user credentials
 */
export const verifyLogin = (data: LoginRequestBody) =>
  http.put<VerifyLoginResponse>(`/login`, data)

/**
 * Logout.
 */
export const logout = () => http.delete(`/login`)

/**
 * Get Worker Info
 * Returns user Orgs and employeeIds.
 */
export const getWorker = () => http.get<LoginWorkerData[]>(`/worker`)

export const getUser = () => http.get<User>('/user')

/**
 * Login Worker
 * Returns a token.
 * @param data Contains user credentials
 */
export const loginWorker = (data: LoginWorkerPayload) =>
  http.post(`/worker`, data)

export const sendEmailVerification = (email: string) =>
  http.post('/verification/email', { email })
