import styled from 'styled-components'

export const UL = styled.ul`
  list-style: none;
  padding: 0;

  & > li:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space[4]};
  }
`
