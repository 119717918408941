import {
  queryOptions,
  useMutation,
  useMutationState,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { AxiosError } from 'axios'
import { useContext } from 'react'

import {
  createAccount,
  detectExistingAccount,
  getTerms,
  linkExistingAccount,
  searchRoster,
  sendVerificationDeeplink,
  updateAccountEmail,
  updateAccountEmailOTP,
  updateAccountPhone,
  updateAccountPhoneDeeplink,
  updateAccountPhoneOTP,
  updateAccountPhoneVerifyEmail,
  verifyUser,
} from '@/api/registration'
import { DialogContext } from '@/context'
import { useAuth } from '@/context/Auth'
import { handleHttpException } from '@/lib/httpException'
import {
  identifyUser,
  sendAnalyticsEvent,
  setUserProperties,
} from '@/lib/mixpanel'
import { OldIResponse } from '@/types/common'
import {
  CreateAccountRequestBody,
  DetectExistingAccountPayload,
  LinkAccountsRequestBody,
  SearchRosterRequestBody,
  SearchRosterResponse,
  SendDeepLinkVerificationRequest,
  UpdateUserEmailBody,
  UpdateUserPhoneBody,
  VerifyUserQueryParams,
} from '@/types/registration'

export const useSendVerificationDeeplink = () => {
  const { orgId } = useAuth()
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async (data: SendDeepLinkVerificationRequest) => {
      const res = await sendVerificationDeeplink(orgId, data)
      return res
    },
    onError: err => {
      const status = (err as AxiosError<OldIResponse>)?.response?.status
      let description = 'There was an error sending the verification link'
      if (status === 404) {
        description = 'Worker not found'
      } else if (status === 409) {
        description = 'Worker is already enrolled'
      }
      showAlert({
        title: 'Error',
        description: description,
        hideCancel: true,
      })
    },
  })
}

export const DETECT_EXISTING_ACCOUNT_QUERY_KEY =
  'DETECT_EXISTING_ACCOUNT_QUERY_KEY'
export const detectExistingAccountQueryOptions = (
  payload: DetectExistingAccountPayload
) =>
  queryOptions({
    queryKey: [DETECT_EXISTING_ACCOUNT_QUERY_KEY],
    queryFn: async () => {
      const res = await detectExistingAccount(payload)
      return res.data
    },
    gcTime: Infinity,
    staleTime: Infinity,
  })

export const useVerifyUser = () => {
  const { orgId } = useAuth()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async (params: VerifyUserQueryParams) => {
      const { track_id, password } = params
      const res = await verifyUser(orgId, { track_id, password })
      return res.data
    },

    onSuccess: async (data, { track_id }) => {
      const existingAccount = await queryClient.ensureQueryData(
        detectExistingAccountQueryOptions({ org_id: orgId, track_id })
      )
      if (existingAccount?.linked_orgs?.length > 0) {
        return navigate({ to: '/setup/existing-account', search: { track_id } })
      }
      setUserProperties({ email: data?.email })
      sendAnalyticsEvent('ONBOARDING', 'verification:success')
      navigate({
        to: '/setup/password-creation',
        state: state => ({
          ...state,
          email: data?.email,
          track_id,
        }),
      })
    },

    onError: (_, params) => {
      sendAnalyticsEvent('ONBOARDING', 'verification:failure')
      showAlert({
        title: params.org_passcode_required
          ? 'Incorrect Passcode'
          : `Invalid last 4.`,
        description: params.org_passcode_required
          ? 'Please check your spelling or reach out to your manager for the correct passcode.'
          : `Please check your last 4 and try again.`,
        hideCancel: true,
      })
    },
  })
}

export const useLinkExistingAccount = () => {
  const { orgId } = useAuth()
  const { showAlert } = useContext(DialogContext)
  const navigate = useNavigate()

  return useMutation({
    mutationFn: (data: LinkAccountsRequestBody) =>
      linkExistingAccount(orgId, data),
    onSuccess: () => {
      navigate({ to: '/debit-card' })
    },
    onError: () => {
      showAlert({
        title: 'Account linking failed',
        description:
          'There was an error linking your account. Please contact support if the issue persists.',
        hideCancel: true,
      })
    },
  })
}

export const useCreateAccount = () => {
  const { orgId } = useAuth()
  const { showAlert } = useContext(DialogContext)
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (data: CreateAccountRequestBody) => {
      const res = await createAccount(orgId, data)
      return res
    },

    onSuccess: ({ status, data: { user_id } }, payload) => {
      identifyUser(user_id)
      sendAnalyticsEvent('ONBOARDING', 'create-account:success')
      if (status === 201) {
        return navigate({
          to: '/setup/email-verification',
          state: {
            loginRequestBody: {
              email: payload.email,
              password: payload.password,
            },
          },
        })
      }

      navigate({
        to: '/debit-card',
      })
    },

    onError: () => {
      sendAnalyticsEvent('ONBOARDING', 'create-account:failure')
      showAlert({
        title: `Account creation failed.`,
        description: `There was an error when creating your account. Please contact support if the issue persists.`,
        hideCancel: true,
      })
    },
  })
}

const GET_TERMS_QUERY = 'get-terms-query'
export const useTerms = (options?: { enabled?: boolean }) => {
  const { enabled = true } = options || {}
  return useQuery({
    queryKey: [GET_TERMS_QUERY],
    queryFn: async () => {
      const res = await getTerms()
      return res.data
    },
    enabled,
  })
}

export const useUpdateAccountEmailOTP = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async () => {
      const res = await updateAccountEmailOTP()
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'email-otp:success')
    },

    onError: error => {
      sendAnalyticsEvent('UPDATE', 'email-otp:failure')
      handleHttpException(error, {
        onHttpError: error => {
          const status = error.response?.status
          if (status === 403) {
            return showAlert({
              title: `Error`,
              description: `User does not have verified phone. Please verify phone number and then try again.`,
              hideCancel: true,
            })
          }
          showAlert({
            title: `Invalid email address.`,
            description: `Please check your email address and try again.`,
            hideCancel: true,
          })
        },
      })
    },
  })
}

export const useUpdateAccountEmail = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async (data: UpdateUserEmailBody) => {
      const res = await updateAccountEmail(data)
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'email:success')
    },

    onError: () => {
      sendAnalyticsEvent('UPDATE', 'email:failure')
      showAlert({
        title: `Invalid code.`,
        description: `Please try again or request a new code by tapping resend code.`,
        hideCancel: true,
      })
    },
  })
}

export const useUpdateAccountPhoneDeeplink = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async () => {
      const res = await updateAccountPhoneDeeplink()
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'phone-deeplink:success')
    },

    onError: () => {
      sendAnalyticsEvent('UPDATE', 'phone-deeplink:failure')
      showAlert({
        title: `Invalid phone number.`,
        description: `Please check your phone number and try again.`,
        hideCancel: true,
      })
    },
  })
}

const GET_PHONE_UPDATE_EMAIL_VERIFICATION =
  'get-phone-update-email-verification-query'
export const usePhoneUpdateEmailVerification = () => {
  return useQuery({
    queryKey: [GET_PHONE_UPDATE_EMAIL_VERIFICATION],
    queryFn: async () => {
      const res = await updateAccountPhoneVerifyEmail()
      return res.data
    },
  })
}

export const useUpdateAccountPhoneOTP = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async (phone: string) => {
      const res = await updateAccountPhoneOTP(phone)
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'phone-otp:success')
    },

    onError: (error: unknown) => {
      sendAnalyticsEvent('UPDATE', 'phone-otp:failure')
      const errorMessage = (error as AxiosError<OldIResponse>)?.response?.data
        .message
      if (errorMessage === 'Email verification not completed') return
      showAlert({
        title: `Invalid phone number.`,
        description: `Please check your phone number and try again.`,
        hideCancel: true,
      })
    },
  })
}

export const useUpdateAccountPhone = () => {
  const { showAlert } = useContext(DialogContext)
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (data: UpdateUserPhoneBody) => {
      const res = await updateAccountPhone(data)
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'phone:success')
      showAlert({
        title: `Phone Update Success.`,
        description: `Successfully updated your phone number.`,
        hideCancel: true,
        handleAction: () =>
          navigate({
            to: '/account',
          }),
      })
    },
    onError: () => {
      sendAnalyticsEvent('UPDATE', 'phone:failure')
      showAlert({
        title: `Invalid code.`,
        description: `Please try again or request a new code by tapping resend code.`,
        hideCancel: true,
      })
    },
  })
}

export const SEARCH_ROSTER = 'search-roster'

export const useSearchRoster = () => {
  const { orgId } = useAuth()

  return useMutation({
    mutationKey: [SEARCH_ROSTER, orgId],
    mutationFn: async (data: SearchRosterRequestBody) => {
      const res = await searchRoster(orgId, data)
      return res.data
    },
  })
}

export const useSearchRosterMutationState = () => {
  const { orgId } = useAuth()
  const data = useMutationState({
    filters: { mutationKey: [SEARCH_ROSTER, orgId] },
    select: mutation => {
      return mutation.state.data as SearchRosterResponse
    },
  })
  /*
   * Mutation state stores mutation responses in an array with the latest response at the end.
   * Grab the most recent response as that is the most up-to-date for the user.
   */
  return data[data.length - 1]
}
