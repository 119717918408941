import { DetailedAlert, theme } from '@branch-messenger/willow-ui'
import { useQuery } from '@tanstack/react-query'

import { Button } from '@/components/button'
import { Box, Flex } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Typography } from '@/components/typography'
import { paymentProfileQueryOptions } from '@/queries/profile'

import { ErrorIndicator, StatusIndicator } from './styles'

export const AccountLoadingError = () => {
  const { refetch } = useQuery(paymentProfileQueryOptions)
  return (
    <>
      <DetailedAlert
        type="info"
        title="Note"
        style={{ marginBottom: theme.space[4] }}
      >
        <Typography size="sm">
          Some account actions are currently unavailable. Please try again in a
          few minutes, and if the issue persists, contact our support team.
        </Typography>
      </DetailedAlert>
      <Typography as="h2" css={{ mb: '$16' }}>
        Payout Method
      </Typography>
      <Box css={{ mb: '$16' }}>
        <StatusIndicator>
          <SVGIcon
            iconName="no_payment_selected"
            wrapperStyle={{ display: 'flex', width: '$48' }}
          />
          <ErrorIndicator>
            <SVGIcon
              iconName="exclamation_mark"
              wrapperStyle={{
                display: 'flex',
                backgroundColor: '$alert',
                borderRadius: '$lg',
              }}
            />
          </ErrorIndicator>
        </StatusIndicator>
        <Flex direction="column" align="start">
          <Typography color="muted" bold>
            Unable to display payout method
          </Typography>
          <Typography color="muted" size="xs">
            Try refreshing in a few minutes
          </Typography>
        </Flex>
        <Flex css={{ ml: 'auto' }}>
          <Button
            mode="ghost"
            css={{ fontWeight: '$bold' }}
            onClick={() => refetch()}
          >
            Refresh
          </Button>
        </Flex>
      </Box>
    </>
  )
}
