import {
  Box,
  ColorKey,
  Progress,
  Typography,
} from '@branch-messenger/willow-ui'
import { FC } from 'react'

type Props = {
  pwStrength: number
  label: string
  color: ColorKey
}

export const PasswordStrengthIndicator: FC<Props> = ({
  pwStrength,
  label,
  color,
}) => (
  <Box $display="flex" $direction="column" $gap={2} $fullWidth>
    <Box $display="flex" $justify="space-between" $fullWidth>
      <Typography>Strength:</Typography>
      <Typography>{label}</Typography>
    </Box>
    <Progress value={pwStrength} $color={color} $size={2} />
  </Box>
)
