import { Box } from '@branch-messenger/willow-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSearch } from '@tanstack/react-router'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { BookmarkPopup } from '@/components/bookmark-popup/bookmark-popup'
import { useLogin } from '@/queries/auth'
import { useLinkExistingAccount, useTerms } from '@/queries/registration'
import { AuthResponse, VerificationChannel } from '@/types/auth'

import { LoginCodeVerificationForm } from './LoginCodeVerificationForm'
import { LoginFormContent } from './LoginFormContent'

export type LoginData = {
  email: string
  password: string
  phoneLastFourDigits?: string
}

const schema = yup.object().shape({
  email: yup.string().email('Must be a valid email address').required(),
  password: yup.string().required('Password is required'),
})

type LoginFormValues = yup.InferType<typeof schema>

const loginFormInitialValues = {
  email: '',
  password: '',
}

export const Login = () => {
  const [loginData, setLoginData] = useState<LoginData | null>(null)
  const [verificationChannel, setVerificationChannel] =
    useState<VerificationChannel>('PHONE')
  const { org_name, track_id } = useSearch({ from: '/login' })
  const isLinkingAccount = !!track_id && !!org_name
  const { data: terms } = useTerms({ enabled: isLinkingAccount })
  const loginRequest = useLogin()
  const linkExistingAccount = useLinkExistingAccount()

  const formMethods = useForm({
    defaultValues: loginFormInitialValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: LoginFormValues) => {
    if (isLinkingAccount) {
      return linkExistingAccount.mutate({
        email: values.email,
        password: values.password,
        track_id,
        terms: (terms || []).map(({ name, version }) => ({ name, version })),
      })
    }

    loginRequest.mutate(values, {
      onSuccess: (res: AuthResponse) => {
        if ('phone_last_four_digits' in res) {
          setVerificationChannel('PHONE')
          setLoginData({
            email: values.email,
            password: values.password,
            phoneLastFourDigits: res.phone_last_four_digits,
          })
        } else if ('email_masked' in res) {
          setVerificationChannel('EMAIL')
          setLoginData({
            email: values.email,
            password: values.password,
          })
        }
      },
    })
  }

  return (
    <>
      {!linkExistingAccount && <BookmarkPopup />}
      {loginData ? (
        <LoginCodeVerificationForm
          loginData={loginData}
          verificationChannel={verificationChannel}
          setVerificationChannel={setVerificationChannel}
        />
      ) : (
        <FormProvider {...formMethods}>
          <Box
            as="form"
            onSubmit={formMethods.handleSubmit(onSubmit)}
            $fullWidth
          >
            <LoginFormContent />
          </Box>
        </FormProvider>
      )}
    </>
  )
}
