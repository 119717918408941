import { Checkbox } from '@branch-messenger/willow-ui'
import { FC } from 'react'

import { UL } from './styles'
import { PasswordValidity } from './types'

type Props = {
  passwordValidity: PasswordValidity
}

export const PasswordRequirements: FC<Props> = ({ passwordValidity }) => (
  <UL>
    <li>
      <Checkbox
        $rounded
        checked={!!passwordValidity.charLength}
        label="At least 8 characters"
        $color="success"
      />
    </li>
    <li>
      <Checkbox
        $rounded
        checked={passwordValidity.hasLetter}
        label="At least 1 letter (a, b, c...)"
        $color="success"
      />
    </li>
    <li>
      <Checkbox
        $rounded
        checked={passwordValidity.hasDigit}
        label="At least 1 digit (1, 2, 3...)"
        $color="success"
      />
    </li>
    <li>
      <Checkbox
        $rounded
        checked={passwordValidity.cases}
        label="Include both uppercase and lowercase characters"
        $color="success"
      />
    </li>
    <li>
      <Checkbox
        $rounded
        checked={passwordValidity.special}
        label="At least 1 special character (!, @, #...)"
        $color="success"
      />
    </li>
  </UL>
)
