import { createFileRoute } from '@tanstack/react-router'

import { AccountManagement } from '@/containers/Account/AccountManagement'
import { userQueryOptions } from '@/queries/auth'

export const Route = createFileRoute('/_authenticated/account/management')({
  component: AccountManagement,
  loader: ({ context }) =>
    context.queryClient.ensureQueryData(userQueryOptions),
})
