import { Box, Popover } from '@branch-messenger/willow-ui'
import { FC, ReactElement, useEffect, useState } from 'react'

import { Typography } from '@/components/typography'
import { usePasswordStrengthValidator } from '@/hooks/usePasswordStrengthValidator'

import { PasswordRequirements } from './PasswordRequirements'
import { PasswordStrengthIndicator } from './PasswordStrengthIndicator'

type Props = {
  password: string
  trigger: ReactElement
}

export const PasswordStrengthPopOver: FC<Props> = ({ trigger, password }) => {
  const [open, setOpen] = useState(false)

  const { passwordValidity, pwStrength, pWStrengthLabel, pWStrengthColor } =
    usePasswordStrengthValidator(password)

  useEffect(() => {
    if (password) setOpen(true)
  }, [password])

  return (
    <Popover
      trigger={trigger}
      open={open}
      onOpenChange={setOpen}
      onOpenAutoFocus={e => {
        e.preventDefault()
      }}
    >
      <Box $display="flex" $direction="column" $gap={4} $align="start">
        <Typography bold>Your password must contain:</Typography>
        <PasswordRequirements passwordValidity={passwordValidity} />
        <PasswordStrengthIndicator
          pwStrength={pwStrength}
          label={pWStrengthLabel}
          color={pWStrengthColor}
        />
      </Box>
    </Popover>
  )
}
